"use client";

import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";
import { IWindowWithPAQ } from "~/lib/track/interfaces";
import { MATOMO_JS, MATOMO_PHP, MATOMO_SITE_ID } from "~/lib/trackingConstants";

interface ITrackProps {}

const Track: React.FC<ITrackProps> = () => {
	const pathname = usePathname();
	const searchParams = useSearchParams();
	useEffect(() => {
		const windowWithPAQ = window as IWindowWithPAQ;
		if (!windowWithPAQ._paq) {
			windowWithPAQ._paq = [];
			const _paq = windowWithPAQ._paq;
			_paq.push(["disableCookies"]);
			_paq.push(["disableAlwaysUseSendBeacon"]);
			_paq.push(["enableLinkTracking"]);
			_paq.push(["setTrackerUrl", `${process.env.NEXT_PUBLIC_MATOMO_URL || ""}/${MATOMO_PHP}`]);
			_paq.push(["setSiteId", `${MATOMO_SITE_ID}`]);
		}
	}, []);
	useEffect(() => {
		setTimeout(function () {
			const _paq = (window as IWindowWithPAQ)._paq;
			if (_paq) {
				_paq.push(["setCustomUrl", pathname + searchParams.toString()]);
				_paq.push(["setDocumentTitle", document.title]);
				_paq.push(["trackPageView"]);
			}
		}, 3000);
	}, [pathname, searchParams]);

	return (
		<>
			<Script src={`${process.env.NEXT_PUBLIC_MATOMO_URL || ""}/${MATOMO_JS}`} />
		</>
	);
};

export default Track;
